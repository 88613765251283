import React from 'react'

const ContactForm = () => (
    <div className="contact-form has-background-light">
        <h1 className="title is-5">Ota yhteyttä</h1>
        <form
            name="contact"
            action="/kiitos-yhteydenotosta"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
        >
            <input type="hidden" name="form-name" value="contact" />
            <div className="field">
                <div className="control">
                    <input
                        className="input has-background-white"
                        type="text"
                        placeholder="Nimi"
                        name="name"
                        required
                        aria-label="Name input"
                    />
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <input
                        className="input has-background-white"
                        type="text"
                        placeholder="Sähköposti"
                        name="email"
                        required
                        aria-label="Email input"
                    />
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <textarea
                        className="textarea has-background-white"
                        placeholder="Viesti"
                        name="message"
                        required
                        aria-label="Message input"
                    />
                </div>
            </div>
            <div className="field">
                <button
                    className="button is-primary is-medium"
                    id="submit-button"
                    type="submit"
                >
                    Lähetä
                </button>
            </div>
        </form>
    </div>
)
export default ContactForm;