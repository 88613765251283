import React from "react"
import { Link, graphql } from "gatsby"

import { MdHome, MdPerson, MdLocationOn, MdEmail, MdPhone } from 'react-icons/md';
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactform";

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Yhteystiedot" />
      <section className="section is-relative is-paddingless page-image">
        <Img
          fluid={data.file.childImageSharp.fluid}
          className="cover">
        </Img>
      </section>
      <section className="section page-title has-background-light">
        <div className="container">
          <h1 className="title is-5 is-uppercase">yhteystiedot</h1>
        </div>
      </section>
      <section className="section">
        <div className="container is-flex">
          <div className="columns">
            <div className="column contact-column">
              <p className="flex-center contact-item"><MdHome /> Mallikatu 6</p>
              <p className="flex-center contact-item"><MdLocationOn />20100 Turku</p>
            </div>
            <div className="column contact-column">
              <p className="flex-center contact-item"><MdPerson />Yhteyshenkilö</p>
              <p className="flex-center contact-item"><MdPhone /> Puhelin: 04444444</p>
              <p className="flex-center contact-item"><MdEmail /> malli@email.com</p>
            </div>
            <div className="column contact-column">
              <p className="flex-center contact-item"><MdPerson />Yhteyshenkilö</p>
              <p className="flex-center contact-item"><MdPhone /> Puhelin: 04444444</p>
              <p className="flex-center contact-item"><MdEmail /> malli@email.com</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <ContactForm />
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="cta-buttons buttons flex-div">
            <Link className="button is-primary is-medium" role="button" to="/tietoa-kohteesta">Tietoa kohteesta</Link>
            <Link className="button is-primary is-medium" role="button" to="/">Etusivu</Link>
          </div>
        </div>
      </section>
    </Layout >
  )
}

export const query = graphql`
query ContactPageQuery {
  file(relativePath: {eq: "03.jpg"}) {
    childImageSharp {
      fluid(quality: 80, maxWidth: 1300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
export default ContactPage
